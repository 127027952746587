<template>
    <div class="wrapper">
        <CRow>
            <CCol lg="12">
                <CButton
                    @click="$router.go(-1)"
                    size="sm"
                    color="warning" 
                > 
                    <font-awesome-icon icon="arrow-left"/> Go back
                </CButton>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol lg="12" v-if="form_data">
                <CCardHeader><font-awesome-icon icon="pencil-alt"/> Manage {{form_data.name}} Data
                    <template v-if="form_data.status == 'Active'">
                        <CButton 
                            size="sm"
                            color="danger" 
                            class="float-right" 
                            @click="toggleEdit(), returnInformation()" 
                            v-show="edit"
                        >
                            <font-awesome-icon icon="window-close"/> Cancel
                        </CButton>

                        <CButton 
                            v-if="$store.getters['can']('update-form-data')"
                            size="sm"
                            color="success" 
                            class="float-right" 
                            v-show="edit" 
                            @click="updateFormData()"
                        >
                            <font-awesome-icon icon="save"/> Update
                        </CButton>

                        <CButton 
                            v-if="$store.getters['can']('edit-form-data')"
                            size="sm"
                            color="info" 
                            class="float-right" 
                            @click="toggleEdit" v-show="!edit"
                        >
                            <font-awesome-icon icon="edit"/> Edit
                        </CButton>
                        <CButton 
                            v-if="routing_status"
                            size="sm"
                            color="warning" 
                            style="margin-right:10px;"
                            class="float-right" 
                            @click="updateMainFileInTheDocumentRouting()" 
                            v-show="!edit"
                        >
                            <font-awesome-icon icon="file-arrow-up"/> Update Main File in the Document Routing
                        </CButton> 
                    </template>
                    <template v-else>
                        <CButton 
                            v-if="$store.getters['can']('restore-form-data')"
                            size="sm"
                            color="success" 
                            class="float-right" 
                            @click="restore()"
                        >
                        <font-awesome-icon icon="trash-restore"/> Restore
                        </CButton>
                    </template>
                </CCardHeader>
                <CCard >
                    <CCardBody :key="cancelEditCounter">

                        <h5 style="color: red; text-align: right;">{{`Ref. No.: ${$route.params.id.split('~')[0]}`}}</h5>
                        <h5 v-if="routing_status">Routing Status: 
                            <CBadge :color="getColorStatus(getDocumentStatus(routing_status))">
                                {{getDocumentStatus(routing_status).toUpperCase()}}
                            </CBadge>
                        </h5>
                        <span v-for="(usageElement, usageIndex) in header_fields" v-bind:key="usageIndex">
                            <CRow v-for="(rowElement, rowElementIndex) in usageElement" v-bind:key="rowElementIndex">
                                <template v-for="(singleElement, singleElementIndex) in rowElement" >
                                    <CCol v-bind:key="singleElementIndex">
                                        <template v-if="singleElement.field_id">
                                            <template v-if="singleElement.field.field_type.name == 'label'">
                                                <div :style="`white-space: pre-line; text-align:${singleElement.field.label_format}` ">{{singleElement.field.label_value}}</div>
                                                <br>
                                            </template>
                                            <template  v-if="singleElement.field.field_type.name == 'text' 
                                                            || singleElement.field.field_type.name == 'number'
                                                                || singleElement.field.field_type.name == 'number_format' 
                                                                    || singleElement.field.field_type.name == 'date'">
                                                <CInput 
                                                    :class="edit ? singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : '' : ''"
                                                    :disabled="!edit"
                                                    :readonly="singleElement.readonly == '0' ? false : true"
                                                    v-if="singleElement.field.field_type.name == 'text'"
                                                    :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name"
                                                    :type="singleElement.field.field_type.name" 
                                                    :placeholder="`Enter ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`"
                                                    autocomplete="off" 
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                />
                                                <CInput 
                                                    :disabled="!edit"
                                                    :readonly="singleElement.readonly == '0' ? false : true"
                                                    :class="singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : ''"
                                                    v-if="singleElement.field.field_type.name == 'number' || singleElement.field.field_type.name == 'number_format'"
                                                    :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name"
                                                    v-numberonly
                                                    ondrop="return false;" 
                                                    :placeholder="`Enter ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`"
                                                    autocomplete="off" 
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                />
                                                <label v-if="singleElement.field.field_type.name == 'date'">{{singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}}</label>
                                                <vc-date-picker
                                                    :key="input_date_key"
                                                    :class="edit ? singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : '': ''"
                                                    v-if="singleElement.field.field_type.name == 'date'"
                                                    mode="date"
                                                    :masks="masks"
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                >
                                                    <template v-slot="{ inputValue, inputEvents }">
                                                        <CRow>
                                                            <CCol lg="12">
                                                                <CInput
                                                                    :class="edit ? 'field-read-only' : ''"
                                                                    readonly
                                                                    :disabled="!edit || (singleElement.readonly == '0' ? false : true)"
                                                                    :placeholder="`Select ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`"
                                                                    :value="inputValue"
                                                                    v-on="inputEvents"
                                                                >
                                                                    <template #append>
                                                                        <span v-if="!singleElement.validation">
                                                                            <CButton 
                                                                                title="Clear Date"
                                                                                color="danger"
                                                                                :disabled="!edit || !inputValue"
                                                                                @click="header_footer_field_values[singleElement.id] = null, input_date_key++"
                                                                            >
                                                                                <font-awesome-icon icon="times"/> 
                                                                            </CButton>
                                                                        </span>
                                                                    </template>
                                                                </CInput>
                                                            </CCol>
                                                        </CRow>
                                                    </template>
                                                </vc-date-picker>
                                            </template>
                                            <CTextarea
                                                :class="edit ? singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-txtarea-error grp-text-area' : 'has-txtarea-success grp-text-area' : 'grp-text-area' : ''"
                                                :disabled="!edit"
                                                :readonly="singleElement.readonly == '0' ? false : true"
                                                v-if="singleElement.field.field_type.name == 'textarea'"
                                                :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name"
                                                :placeholder="`Enter brief ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`"
                                                autocomplete="off"  
                                                v-model="header_footer_field_values[singleElement.id]"
                                            />
                                            <CInput 
                                                :key="input_search_key"
                                                :class="edit ? singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : '' : ''"
                                                :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name" 
                                                v-if="singleElement.field.field_type.name == 'input_search'"
                                                :placeholder="`Please select ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`"
                                                type="text" 
                                                autocomplete="off" 
                                                readonly 
                                                v-model="header_footer_field_values[singleElement.id]"
                                            >
                                                <template #append >
                                                    <CButton color="primary" 
                                                        title="Search"
                                                        :disabled="!edit" 
                                                        @click="inputSearch(singleElement, 'header')"
                                                    >
                                                    <font-awesome-icon icon="search"/>
                                                    </CButton>
                                                    <CButton color="danger" 
                                                        title="Clear"
                                                        :disabled="!edit" 
                                                        @click="inputClear(singleElement, 'header')"
                                                    >
                                                        <font-awesome-icon icon="times"/>
                                                    </CButton>
                                                </template>
                                            </CInput>
                                            <v-select
                                                :key="dropdown_key"
                                                :readonly="singleElement.readonly == '0' ? false : true"
                                                :class="edit ? singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-error' : 'has-success' : '' : ''"
                                                :disabled="!edit" 
                                                v-model="header_footer_field_values[singleElement.id]"
                                                v-if="singleElement.field.field_type.name == 'dropdown'"
                                                :placeholder="`Please select ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`"
                                                :options="singleElement.field.field_drop_down_values_data"
                                                label="value"
                                                @option:selected="dropdown_key++"
                                                @input="dropdown_key++"
                                                :reduce="value => value.value" 
                                            >
                                                <template #header>
                                                    <div style="padding-bottom:9px;">{{singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}}</div>
                                                </template>
                                            </v-select>
                                            <br v-if="singleElement.field.field_type.name == 'dropdown'">
                                        </template>
                                        <template v-if="singleElement.aggregate_function">
                                            <span><small><font-awesome-icon icon="info-circle" style="color: #4caf50;"/> {{singleElement.aggregate_function[0].toUpperCase() + singleElement.aggregate_function.slice(1).toLowerCase()}} of {{singleElement.aggregate_column_field ? singleElement.aggregate_column_field.parameter_name : null}} will be refresh upon updating of the form.</small></span>
                                            <CInput 
                                                readonly
                                                v-model="header_footer_field_values[singleElement.id]"
                                                type="text" 
                                                :placeholder="`${singleElement.aggregate_function} of ${singleElement.aggregate_column_field ? singleElement.aggregate_column_field.parameter_name : null}`" 
                                                autocomplete="off" 
                                            />
                                        </template>
                                    </CCol>
                                </template>
                            </CRow>
                        </span>
                        
                        <template v-if="detail_fields.length > 0"> <!-- this is for lines -->
                            <hr>
                            <CRow>
                                <CCol lg="12" >
                                    <CRow v-for="index in detailsRowCount" v-bind:key="index">
                                            <CCol :lg="!edit ? '12' : '11'">
                                            <CRow>
                                                <CCol v-for="(detailElement, detailIndex) in detail_fields" v-bind:key="detailIndex">
                                                <template v-if="detailElement.field_id">
                                                    <template v-if="detailElement.field.field_type.name == 'label'">
                                                        <div :style="`white-space: pre-line; text-align:${detailElement.field.label_format}` ">{{detailElement.field.label_value}}</div>
                                                        <br>
                                                    </template>
                                                    <template  v-if="detailElement.field.field_type.name == 'text' 
                                                                || detailElement.field.field_type.name == 'number' 
                                                                    || detailElement.field.field_type.name == 'number_format' 
                                                                        || detailElement.field.field_type.name == 'date'">
                                                        <CInput 
                                                            :class="edit ? detailElement.validation && detailElement.validation.split('|')[0] == 'required' ? !detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id] ? 'has-inp-error' : 'has-inp-success' : '' : ''"
                                                            :disabled="!edit"
                                                            :readonly="detailElement.readonly == '0' ? false : true"
                                                            v-if="detailElement.field.field_type.name == 'text'"
                                                            :label="detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name"
                                                            :type="detailElement.field.field_type.name" 
                                                            :placeholder="`Enter ${detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}`" 
                                                            autocomplete="off" 
                                                            v-model="detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id]"
                                                        />
                                                        <CInput 
                                                            :disabled="!edit"
                                                            :readonly="detailElement.readonly == '0' ? false : true"
                                                            :class="edit ? detailElement.validation && detailElement.validation.split('|')[0] == 'required' ? !detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id] ? 'has-inp-error' : 'has-inp-success' : '' : ''"
                                                            v-if="detailElement.field.field_type.name == 'number' || detailElement.field.field_type.name == 'number_format'"
                                                            :label="detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name"
                                                            v-numberonly
                                                            ondrop="return false;" 
                                                            :placeholder="`Enter ${detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}`" 
                                                            autocomplete="off" 
                                                            v-model="detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id]"
                                                        />
                                                        <label v-if="detailElement.field.field_type.name == 'date'">{{detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}}</label>
                                                        <vc-date-picker
                                                            :key="input_date_key"
                                                            :class="edit ? detailElement.validation && detailElement.validation.split('|')[0] == 'required' ? !detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id] ? 'has-inp-error' : 'has-inp-success' : '' : ''"
                                                            v-model="detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id]"
                                                            v-if="detailElement.field.field_type.name == 'date'"
                                                            mode="date"
                                                            :masks="masks"
                                                        >
                                                            <template v-slot="{ inputValue, inputEvents  }">
                                                                <CRow>
                                                                    <CCol lg="12">
                                                                        <CInput
                                                                            :disabled="!edit || (detailElement.readonly == '0' ? false : true)"
                                                                            :class="edit ? 'field-read-only' : ''"
                                                                            readonly
                                                                            :placeholder="`Select ${detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}`" 
                                                                            :value="inputValue"
                                                                            v-on="inputEvents"
                                                                        >
                                                                            <template #append>
                                                                                <span v-if="!detailElement.validation">
                                                                                    <CButton 
                                                                                        title="Clear Date"
                                                                                        color="danger"
                                                                                        :disabled="!edit || !inputValue"
                                                                                        @click="detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id] = null, input_date_key++"
                                                                                    >
                                                                                        <font-awesome-icon icon="times"/> 
                                                                                    </CButton>
                                                                                </span>
                                                                            </template>
                                                                        </CInput>
                                                                    </CCol>
                                                                </CRow>
                                                            </template>
                                                        </vc-date-picker>
                                                    </template>
                                                    <CTextarea
                                                        :readonly="detailElement.readonly == '0' ? false : true"
                                                        :class="edit ? detailElement.validation && detailElement.validation.split('|')[0] == 'required' ? !detail_field_values['line_'+index+'#fieldid_'+detailElement.id] ? 'has-txtarea-error grp-text-area' : 'has-txtarea-success grp-text-area' : 'grp-text-area' : ''"
                                                        :disabled="!edit"
                                                        :label="detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name"
                                                        v-if="detailElement.field.field_type.name == 'textarea'"
                                                        :placeholder="`Enter brief ${detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}`" 
                                                        autocomplete="off"  
                                                        v-model="detail_field_values['line_'+index+'#fieldid_'+detailElement.id]"
                                                    />
                                                    <CInput 
                                                            :key="input_search_key"
                                                            :class="detailElement.validation && detailElement.validation.split('|')[0] == 'required' ? !detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id] ? 'has-inp-error' : 'has-inp-success' : ''"
                                                            :label="detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name" 
                                                            v-if="detailElement.field.field_type.name == 'input_search'"
                                                            :placeholder="`Please select ${detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}`" 
                                                            type="text" 
                                                            autocomplete="off" 
                                                            readonly 
                                                            v-model="detail_field_values['line_'+index+'#fieldid_'+detailElement.id]"
                                                        >
                                                            <template #append>
                                                                <CButton color="primary" 
                                                                    title="Search"
                                                                    :disabled="!edit" 
                                                                    @click="inputSearch(detailElement, 'detail', index)"
                                                                >
                                                                <font-awesome-icon icon="search"/>
                                                                </CButton>
                                                                <CButton color="danger" 
                                                                    title="Clear"
                                                                    :disabled="!edit" 
                                                                    @click="inputClear(detailElement, 'detail', index)"
                                                                >
                                                                <font-awesome-icon icon="times"/>
                                                                </CButton>
                                                            </template>
                                                    </CInput>
                                                    <v-select 
                                                        :readonly="detailElement.readonly == '0' ? false : true"
                                                        :class="edit ? detailElement.validation && detailElement.validation.split('|')[0] == 'required' ? !detail_field_values['line_'+index+'#fieldid_'+detailElement.id] ? 'has-error' : 'has-success' : '' : ''"
                                                        :disabled="!edit"
                                                        v-if="detailElement.field.field_type.name == 'dropdown'"
                                                        :placeholder="`Please select ${detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}`" 
                                                        :options="detailElement.field.field_drop_down_values_data"
                                                        label="value"
                                                        :reduce="value => value.value" 
                                                        @option:selected="dropdown_key++"
                                                        @input="dropdown_key++"
                                                        v-model="detail_field_values['line_'+index+'#fieldid_'+detailElement.id]"
                                                    >
                                                        <template #header>
                                                            <div style="padding-bottom:9px;">{{detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}}</div>
                                                        </template>
                                                    </v-select>
                                                    <br v-if="detailElement.field.field_type.name == 'dropdown'">
                                                </template>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                        
                                        <CCol lg="1" v-if="edit">
                                            <CButton 
                                                title="Remove row"
                                                type="submit" 
                                                shape="pill" 
                                                color="danger"
                                                style="margin-top:50%;"
                                                @click="removeFormDetailFieldElement(index, $event)"
                                            >
                                                <font-awesome-icon icon="times"/>
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                            <CRow v-if="edit">
                                <CCol lg="12">
                                    <CButton 
                                        class="float-right"
                                        shape="pill" 
                                        color="info"
                                        @click="detailsRowCount++"
                                    >
                                        <font-awesome-icon icon="plus"/> Add Row
                                    </CButton>
                                </CCol>
                            </CRow>
                        </template>
                        <hr>
                        <span v-for="(usageElement, usageIndex) in footer_fields" v-bind:key="usageIndex">
                            <CRow v-for="(rowElement, rowElementIndex) in usageElement" v-bind:key="rowElementIndex">
                                <template v-for="(singleElement, singleElementIndex) in rowElement" >
                                    <CCol v-bind:key="singleElementIndex">
                                        <template v-if="singleElement.field_id">
                                            <template v-if="singleElement.field.field_type.name == 'label'">
                                                <div :style="`white-space: pre-line; text-align:${singleElement.field.label_format}` ">{{singleElement.field.label_value}}</div>
                                                <br>
                                            </template>
                                            <template  v-if="singleElement.field.field_type.name == 'text' 
                                                            || singleElement.field.field_type.name == 'number' 
                                                                || singleElement.field.field_type.name == 'number_format' 
                                                                    || singleElement.field.field_type.name == 'date'">
                                                <CInput 
                                                    :class="edit ? singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : '' : ''"
                                                    :disabled="!edit"
                                                    :readonly="singleElement.readonly == '0' ? false : true"
                                                    v-if="singleElement.field.field_type.name == 'text'"
                                                    :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name"
                                                    :type="singleElement.field.field_type.name" 
                                                    :placeholder="`Enter ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                    autocomplete="off" 
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                />
                                                <CInput 
                                                    :disabled="!edit"
                                                    :readonly="singleElement.readonly == '0' ? false : true"
                                                    :class="singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : ''"
                                                    v-if="singleElement.field.field_type.name == 'number' || singleElement.field.field_type.name == 'number_format'"
                                                    :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name"
                                                    v-numberonly
                                                    ondrop="return false;" 
                                                    :placeholder="`Enter ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                    autocomplete="off" 
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                />
                                                <label v-if="singleElement.field.field_type.name == 'date'">{{singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}}</label>
                                                <vc-date-picker
                                                    :key="input_date_key"
                                                    :class="edit ? singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : '': ''"
                                                    v-if="singleElement.field.field_type.name == 'date'"
                                                    mode="date"
                                                    :masks="masks"
                                                    v-model="header_footer_field_values[singleElement.id]"
                                                >
                                                    <template v-slot="{ inputValue, inputEvents }">
                                                        <CRow>
                                                            <CCol lg="12">
                                                                <CInput
                                                                    :class="edit ? 'field-read-only' : ''"
                                                                    readonly
                                                                    :disabled="!edit || (singleElement.readonly == '0' ? false : true)"
                                                                    :placeholder="`Select ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                                    :value="inputValue"
                                                                    v-on="inputEvents"
                                                                >
                                                                    <template #append>
                                                                        <span v-if="!singleElement.validation">
                                                                            <CButton 
                                                                                title="Clear Date"
                                                                                color="danger"
                                                                                :disabled="!edit || !inputValue"
                                                                                @click="header_footer_field_values[singleElement.id] = null, input_date_key++"
                                                                            >
                                                                                <font-awesome-icon icon="times"/>
                                                                            </CButton>
                                                                        </span>
                                                                    </template>
                                                                </CInput>
                                                            </CCol>
                                                        </CRow>
                                                    </template>
                                                </vc-date-picker>
                                            </template>
                                            <CTextarea
                                                :class="edit ? singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-txtarea-error grp-text-area' : 'has-txtarea-success grp-text-area' : 'grp-text-area' : ''"
                                                :disabled="!edit"
                                                :readonly="singleElement.readonly == '0' ? false : true"
                                                v-if="singleElement.field.field_type.name == 'textarea'"
                                                :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name"
                                                :placeholder="`Enter brief ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                autocomplete="off"  
                                                v-model="header_footer_field_values[singleElement.id]"
                                            />
                                            <CInput 
                                                :key="input_search_key"
                                                :class="edit ? singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-inp-error' : 'has-inp-success' : '' : ''"
                                                :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name" 
                                                v-if="singleElement.field.field_type.name == 'input_search'"
                                                :placeholder="`Please select ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                type="text" 
                                                autocomplete="off" 
                                                readonly 
                                                v-model="header_footer_field_values[singleElement.id]"
                                            >
                                                <template #append >
                                                    <CButton color="primary" 
                                                        title="Search"
                                                        :disabled="!edit" 
                                                        @click="inputSearch(singleElement, 'header')"
                                                    >
                                                    <font-awesome-icon icon="search"/>
                                                    </CButton>
                                                    <CButton color="danger" 
                                                        title="Clear"
                                                        :disabled="!edit" 
                                                        @click="inputClear(singleElement, 'footer')"
                                                    >
                                                    <font-awesome-icon icon="times"/>
                                                    </CButton>
                                                </template>
                                            </CInput>
                                            <v-select
                                                :key="dropdown_key"
                                                :readonly="singleElement.readonly == '0' ? false : true"
                                                :class="edit ? singleElement.validation && singleElement.validation.split('|')[0] == 'required' ? !header_footer_field_values[singleElement.id] ? 'has-error' : 'has-success' : '' : ''"
                                                :disabled="!edit" 
                                                v-model="header_footer_field_values[singleElement.id]"
                                                v-if="singleElement.field.field_type.name == 'dropdown'"
                                                :placeholder="`Please select ${singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}`" 
                                                :options="singleElement.field.field_drop_down_values_data"
                                                label="value"
                                                @option:selected="dropdown_key++"
                                                @input="dropdown_key++"
                                                :reduce="value => value.value" 
                                            >
                                                <template #header>
                                                    <div style="padding-bottom:9px;">{{singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}}</div>
                                                </template>
                                            </v-select>
                                            <br v-if="singleElement.field.field_type.name == 'dropdown'">
                                        </template>
                                        <template v-if="singleElement.aggregate_function">
                                            <span><small><font-awesome-icon icon="info-circle" style="color: #4caf50;"/> {{singleElement.aggregate_function[0].toUpperCase() + singleElement.aggregate_function.slice(1).toLowerCase()}} of {{singleElement.aggregate_column_field ? singleElement.aggregate_column_field.parameter_name : null}} will be refresh upon updating of the form.</small></span>
                                            <CInput 
                                                readonly
                                                v-model="header_footer_field_values[singleElement.id]"
                                                type="text" 
                                                :placeholder="`${singleElement.aggregate_function} of ${singleElement.aggregate_column_field ? singleElement.aggregate_column_field.parameter_name : null}`" 
                                                autocomplete="off" 
                                            />
                                        </template>
                                    </CCol>
                                </template>
                            </CRow>
                        </span>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow v-show="edit">
            <CCol lg="12">
                <CCard borderColor="danger" v-if="$store.getters['can']('archive-form-data')">
                    <CCardHeader>
                        <font-awesome-icon 
                            icon="exclamation" 
                            :style="{ color: 'red' }"
                        /> 
                        Danger Zone
                    </CCardHeader>
                    <CCardBody>
                        <CRow v-if="form_data">
                            <CCol lg="8">
                                <h6>Archive this {{form_data.name}} Data ?</h6>
                            </CCol>
                            <CCol lg="12">
                                <label> Once archived, you cannot route this form anymore. Please be certain.</label>
                            </CCol>
                        </CRow>
                        <br>
                        <CRow>
                            <CCol lg="3">
                                <CButton shape="pill" color="warning" @click="archive()" > 
                                    <font-awesome-icon icon="minus-square" /> Archived 
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        
        <template>
            <!-- " -->
            <CCard style="display:none">
            <!-- <CCard> -->
                <CCardBody>
                    <CRow>
                        <CCol lg="12">
                            <FormDataPreview ref="_formDataUpdate" id="form_data_update"/>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </template>
        <CModal
            :show.sync="large_list_modal"
            color="primary"
            size="lg"
            v-if="large_list_selected_element"
            :closeOnBackdrop="false"
            >
            <template #header>
                <h6> <font-awesome-icon icon="list"/> {{ large_list_selected_element.field.display_name}} </h6>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="large_list_modal = false"
                >
                    <font-awesome-icon  size="sm" icon="times"/>
                </CButton>
            </template>
            <template #footer>
                <hr>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <CRow>
                        <CCol lg="6">
                            <CInput 
                                type="text" 
                                placeholder="Search" 
                                autocomplete="off" 
                                v-model="large_list_search"
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="12">
                            <CDataTable
                                :key="large_list_modal_key"
                                :items="large_list_items"
                                :fields="large_list_field"
                                border
                                sorter
                                @row-clicked="largeListSelectedItem"
                                pagination
                            >
                                <template #action="{item, index}">
                                    <td>
                                        <center>
                                            <CButton 
                                                color="danger" 
                                                @click="removeSignatoryRequirement(item, index)" 
                                                shape="pill" 
                                                size="sm"
                                            >
                                                <font-awesome-icon icon="times"/> Remove
                                            </CButton>
                                        </center>
                                    </td>
                                </template>
                            </CDataTable>
                        </CCol>
                    </CRow>
                </CCardBody>
            </template>
        </CModal>
    </div>
</template>
<script>
import html2pdf from 'html2pdf.js'
import FormDataPreview from './components/FormDataPreview'
import FormManagementJS from '../js/form-management.js';
export default {
    name: 'FDEdit',
    components: {
        FormDataPreview
    },
    data(){
        return {
            input_date_key:0,


            deleted_lines:[],

            input_search_key:0,
            large_list_search: '',
            large_list_modal: false,
            large_list_modal_key: 0,
            large_list_selected_element:  null,
            large_list_selected_element_index:  null,
            large_list_selected_element_custom_usage:  null,

            large_list: [],
            large_list_field: [
                { key: 'id'},
                { key: 'value' },
            ],


            dropdown_key: 0,
            cancelEditCounter: 0,
            disable:1,
            edit: false,
            
            detailRowLine: 0,
            form_data: null,

            header_fields:  {},
            footer_fields: {},
            detail_fields: {},
            
            af_and_columns: {},

            //Header - Footer
            header_footer_field_values: {},
            header_footer_field_values_backup: {},
            //End Header - Footer

            //Detail or Line
            detail_field_values: {},
            detail_field_values_backup: {},
            recently_removed_detail_ids: [],
            // End Detail or Line


            masks: {
                input: 'DD-MMMM-YYYY',
            },
        }
    }, 
    created() {
        this.$emit('activeTab', 0);
        this.$Progress.start()
        this.getFormData()
    },
    computed: {
        detailsRowCount:  {
            get() {
                return this.detailRowLine; 
            },
            set(value) {
                this.detailRowLine = value
            }
        },
        large_list_items: function () {
            return this.large_list.filter((e) => e.value.toLowerCase().indexOf(this.large_list_search.toLowerCase()) > -1  || e.id.toString().toLowerCase().indexOf(this.large_list_search.toLowerCase()) > -1);
        }
    },
    methods: {
        archive: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to archive the ${this.form_data.name}.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/form-managements/form-data/archive/' + this.$route.params.id.split('~')[0], {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                timer: 3000,
                                icon: 'success',
                                title: `${this.form_data.name} successfully archived.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            this.form_data.status = 'Inactive'
                            this.cancelEditCounter++;
                            this.toggleEdit()
                            this.$Progress.finish()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        restore: function () {
            this.$Progress.start()
            axios.post('/form-managements/form-data/restore/' + this.$route.params.id.split('~')[0], {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${this.form_data.name} successfully restored.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.form_data.status = 'Active'
                    this.cancelEditCounter++;
                    this.$Progress.finish()
                }
            })




            // this.$swal({
            //     icon: "warning",
            //     title: "Are you sure?",
            //     text: `You are trying to restore the ${this.form_data.name}.`,
            //     showCancelButton: true,
            //     confirmButtonColor: "#DD6B55",
            //     confirmButtonText: "Yes",
            //     showLoaderOnConfirm: true,
            //     allowOutsideClick: false,
            //     allowEscapeKey: false,
            //     preConfirm: () => { 
            //         this.$Progress.start()
            //         return axios.post('/form-managements/form-data/restore/' + this.$route.params.id.split('~')[0], {validateStatus: () => true})
            //         .then(response => {
            //             if(response.status == 200) {
            //                 this.$swal({
            //                     toast: true,
            //                     position: 'top-right',
            //                     timer: 3000,
            //                     icon: 'success',
            //                     title: `${this.form_data.name} successfully restored.`,
            //                     showConfirmButton: false,
            //                     timerProgressBar: true,
            //                 })
            //                 this.form_data.status = 'Active'
            //                 this.cancelEditCounter++;
            //                 this.$Progress.finish()
            //             }
            //         })
            //     }
            // }).then((result) => {
            //     if(!result.value){
            //         this.$swal('Cancelled!','','error');
            //     }
            // })
        },
        inputClear: function (element, custom_usage, index = null) {
            this.input_search_key++;
            if(custom_usage != 'detail') {
                this.header_footer_field_values[element.id] = ''
            } else {
                this.detail_field_values['line_'+index+'#fieldid_'+element.id] = ''
            }
        },
        inputSearch: function (element, custom_usage, index = null) {
            this.large_list_selected_element = element;
            this.large_list_selected_element_index = index;
            this.large_list_selected_element_custom_usage = custom_usage;
            this.large_list = this.large_list_selected_element.field.field_drop_down_values_data;
            this.large_list_modal = true;
        },
        largeListSelectedItem: function (item, index, column, event) {
            if(this.large_list_selected_element_custom_usage == 'header' || this.large_list_selected_element_custom_usage == 'footer') {
                this.header_footer_field_values[this.large_list_selected_element.id] = item.value
            } else {
                this.detail_field_values['line_'+this.large_list_selected_element_index+'#fieldid_'+this.large_list_selected_element.id] = item.value
            }
            this.large_list_modal = false;
        },
        validateFields: function () {
            let required_fields = []
            for (let i = 0; i < this.form_data.form_header_footer_fields.length; i++) {
                const element = this.form_data.form_header_footer_fields[i];
                if(element.validation && element.validation.split('|')[0] == 'required' && !this.header_footer_field_values[element.id]) {
                    required_fields.push(element.field.display_name);
                }
            }

            if( required_fields.length > 0 ) {
                return false
            }
            return true;
        },
        returnInformation: function () {
            this.cancelEditCounter++
            this.$nextTick(() => {
                this.header_footer_field_values = Object.assign({}, this.header_footer_field_values_backup)
                this.detail_field_values = Object.assign({}, this.detail_field_values_backup)
                this.deleted_lines = []
                this.recently_removed_detail_ids = [];
            })
        },
        basicUpdate: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to update the form with reference number <b>${this.$route.params.id.split('~')[0]}</b>`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    let hf_field_data = [];
                    let array_ = [];
                    let d_field_data = []
                    // Data Manipulation-----------
                    
                    // // Header / Footer
                    for (var key of Object.keys(this.header_footer_field_values)) {
                        let fhff_value = this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).form_header_footer_field_value ? 
                                            this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).form_header_footer_field_value.id : null
                        let element = {
                            id: fhff_value, // form header footer field value
                            fhff_id: key,   //form header footer field id 
                            parameter_name: null,
                            // validation: this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).validation,
                            value: this.header_footer_field_values[key],
                        }

                        if(this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).field) {

                            element.parameter_name = this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).field.parameter_name
                            // override the value if the field is date
                            if(this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).field.field_type.name == 'date') {
                                if(this.header_footer_field_values[key] && typeof this.header_footer_field_values[key] == 'object') {
                                    element.value = this.header_footer_field_values[key].toISOString().split('T')[0]
                                }
                            }
                        }
                        hf_field_data.push(element);
                    }
                    // // End Header / Footer
                    
                    // Details or Lines
                    // removing of recently deleted detail fields in the parent object.
                    for (let j = 0; j < this.form_data.form_detail_fields.length; j++) {
                        const parent_el = this.form_data.form_detail_fields[j];
                        for (let k = 0; k < parent_el.form_detail_field_value.length; k++) {
                            const child_el = parent_el.form_detail_field_value[k];
                            if(this.recently_removed_detail_ids.includes(child_el.id)) {
                                parent_el.form_detail_field_value.splice(k, 1)
                            }
                        }
                    }

                    for (var key in this.detail_field_values) {
                        array_.push(key)
                    }
                    array_.sort(function(a, b){return a.localeCompare(b, 'en', { numeric: true })});
                    for (var key in array_) {
                        let str = array_[key].split("#");
                        let field = this.form_data.form_detail_fields.find(item => item.id === parseInt(str[1].split('_')[1])).form_detail_field_value;
                        let row = {
                            id: field.filter(item => item.line_number === str[0].split('_')[1]).length > 0 ? field.filter(item => item.line_number === str[0].split('_')[1])[0].id : null, // form header footer field value
                            fdf_id: str[1].split('_')[1], //field_id
                            value:  this.detail_field_values[array_[key]] ? this.detail_field_values[array_[key]] : null, 
                            parameter_name:  this.form_data.form_detail_fields.find(item => item.id === parseInt(str[1].split('_')[1])).field.parameter_name, 
                            line:  str[0].split('_')[1] //row number = line *line starts in 1 
                        }

                        // updating of lines
                        let currentLine = parseInt(str[0].split('_')[1])
                        let sorted =this.deleted_lines.sort(function(a, b){return b - a});
                        for (let k = 0; k < sorted.length; k++) { //2,3
                            const element = this.deleted_lines[k];
                            if(parseInt(element) <= currentLine) {
                                currentLine = currentLine-1;
                                row.line = currentLine.toString();
                            }
                        }
                        d_field_data.push(row);
                    }
                    // End details or lines

                    let data = {
                        header_footer_field_data: hf_field_data,
                        detail_field_data: d_field_data,
                    }

                    if(this.af_and_columns.length >= 0) {
                        data.af_and_columns = this.af_and_columns;
                    }

                    if(this.recently_removed_detail_ids.length > 0) {
                        data.recently_removed_detail_ids = this.recently_removed_detail_ids;
                    }

                    return axios.post('/form-managements/form-data/update/'+this.$route.params.id, data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.form_data.name}</b> has been updated.`,
                            })
                            this.recently_removed_detail_ids = [];
                            this.deleted_lines = [];
                            this.header_footer_field_values = {};
                            this.header_footer_field_values_backup = {};
                            this.detail_field_values = {};
                            this.detail_field_values_backup = {};
                            this.af_and_columns = [];
                            this.cancelEditCounter++
                            this.getFormData();
                            this.toggleEdit();
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            });
        },
        advanceUpdate: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `This form's routing status is draft. The PDF file with reference number <b>${this.$route.params.id.split('~')[0]}</b> in the document routing will also be updated when this form is updated.<br>
                        <br>
                        <small><i>Please note that there is no way to reverse this action.</i></small>`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    let hf_field_data = [];
                    let array_ = [];
                    let d_field_data = []
                    // Data Manipulation-----------
                    
                    // // Header / Footer
                    for (var key of Object.keys(this.header_footer_field_values)) {
                        let fhff_value = this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).form_header_footer_field_value ? 
                                            this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).form_header_footer_field_value.id : null
                        let element = {
                            id: fhff_value, // form header footer field value
                            fhff_id: key,   //form header footer field id 
                            parameter_name: null,
                            // validation: this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).validation,
                            value: this.header_footer_field_values[key],
                        }

                        if(this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).field) {

                            element.parameter_name = this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).field.parameter_name
                            // override the value if the field is date
                            if(this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).field.field_type.name == 'date') {
                                if(this.header_footer_field_values[key] && typeof this.header_footer_field_values[key] == 'object') {
                                    element.value = this.header_footer_field_values[key].toISOString().split('T')[0]
                                }
                            }
                        }
                        hf_field_data.push(element);
                    }
                    // // End Header / Footer
                    
                    // Details or Lines
                    // removing of recently deleted detail fields in the parent object.
                    for (let j = 0; j < this.form_data.form_detail_fields.length; j++) {
                        const parent_el = this.form_data.form_detail_fields[j];
                        for (let k = 0; k < parent_el.form_detail_field_value.length; k++) {
                            const child_el = parent_el.form_detail_field_value[k];
                            if(this.recently_removed_detail_ids.includes(child_el.id)) {
                                parent_el.form_detail_field_value.splice(k, 1)
                            }
                        }
                    }

                    for (var key in this.detail_field_values) {
                        array_.push(key)
                    }
                    array_.sort(function(a, b){return a.localeCompare(b, 'en', { numeric: true })});
                    for (var key in array_) {
                        let str = array_[key].split("#");
                        let field = this.form_data.form_detail_fields.find(item => item.id === parseInt(str[1].split('_')[1])).form_detail_field_value;
                        let row = {
                            id: field.filter(item => item.line_number === str[0].split('_')[1]).length > 0 ? field.filter(item => item.line_number === str[0].split('_')[1])[0].id : null, // form header footer field value
                            fdf_id: str[1].split('_')[1], //field_id
                            value:  this.detail_field_values[array_[key]] ? this.detail_field_values[array_[key]] : null, 
                            parameter_name:  this.form_data.form_detail_fields.find(item => item.id === parseInt(str[1].split('_')[1])).field.parameter_name, 
                            line:  str[0].split('_')[1] //row number = line *line starts in 1 
                        }

                        // updating of lines
                        let currentLine = parseInt(str[0].split('_')[1])
                        let sorted =this.deleted_lines.sort(function(a, b){return b - a});
                        for (let k = 0; k < sorted.length; k++) { //2,3
                            const element = this.deleted_lines[k];
                            if(parseInt(element) <= currentLine) {
                                currentLine = currentLine-1;
                                row.line = currentLine.toString();
                            }
                        }
                        d_field_data.push(row);
                    }
                    // End details or lines

                    let data = {
                        header_footer_field_data: hf_field_data,
                        detail_field_data: d_field_data,
                    }

                    if(this.af_and_columns.length >= 0) {
                        data.af_and_columns = this.af_and_columns;
                    }

                    if(this.recently_removed_detail_ids.length > 0) {
                        data.recently_removed_detail_ids = this.recently_removed_detail_ids;
                    }

                    return this.update().then(response => {
                        if ( response.status == 200 ) {
                            // this.$swal({
                            //     icon: "success",
                            //     title: "Success!",
                            //     html: `<b>${this.form_data.name}</b> has been updated.`,
                            // })

                            this.recently_removed_detail_ids = [];
                            this.deleted_lines = [];
                            this.header_footer_field_values = {};
                            this.header_footer_field_values_backup = {};
                            this.detail_field_values = {};
                            this.detail_field_values_backup = {};
                            this.af_and_columns = [];
                            this.cancelEditCounter++
                            return this.getFormData().then(response => {

                            });
                            // this.toggleEdit();
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            });
        },
        update: function () {
            return new Promise((resolve, reject) => {
                axios.post('/form-managements/form-data/update/'+this.$route.params.id, data, {validateStatus: () => true})
                .then(response => {
                    resolve (response)
                }).catch(function (err) {
                    console.log(err);
                })
            })
        },
        createPDF: function () {
            return new Promise(async (resolve, reject) => {
                const options = {
                    margin: [.5,.5],
                    image: {type: 'jpeg', quality: 0.98 },
                    html2canvas: {dpi: 300, letterRendering: true,useCORS: true},
                    jsPDF: { unit: 'in', format: 'legal', orientation: 'portrait' },
                    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
                }
                try {
                    const base64 = html2pdf().set(options).from(document.getElementById('form_data_update')).outputPdf().then( (pdf) => {
                        return btoa(pdf)
                    })

                    // const base64 = html2pdf().set(options).from(document.getElementById('form_data')).outputPdf().save()
                    resolve(base64);
                } catch (e) {
                    reject(e);
                }
            });
        },
        updateFormData: function () {
            if(JSON.stringify(this.header_footer_field_values) == JSON.stringify(this.header_footer_field_values_backup) 
                && JSON.stringify(this.detail_field_values) == JSON.stringify(this.detail_field_values_backup)) {
               this.$swal({
                    icon: "info",
                    title: "Opps!",
                    text: "No changes has been made.",
                })
                return;
            }
            if(!this.validateFields()) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
            }
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to update the form with reference number <b>${this.$route.params.id.split('~')[0]}.</b>`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    let hf_field_data = [];
                    let array_ = [];
                    let d_field_data = []
                    // Data Manipulation-----------
                    
                    // // Header / Footer
                    for (var key of Object.keys(this.header_footer_field_values)) {
                        let fhff_value = this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).form_header_footer_field_value ? 
                                            this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).form_header_footer_field_value.id : null
                        let element = {
                            id: fhff_value, // form header footer field value
                            fhff_id: key,   //form header footer field id 
                            parameter_name: null,
                            // validation: this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).validation,
                            value: this.header_footer_field_values[key],
                        }

                        if(this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).field) {

                            element.parameter_name = this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).field.parameter_name
                            // override the value if the field is date
                            if(this.form_data.form_header_footer_fields.find(item => item.id === parseInt(key)).field.field_type.name == 'date') {
                                if(this.header_footer_field_values[key] && typeof this.header_footer_field_values[key] == 'object') {
                                    element.value = this.header_footer_field_values[key].toISOString().split('T')[0]
                                }
                            }
                        }
                        hf_field_data.push(element);
                    }
                    // // End Header / Footer
                    
                    // Details or Lines
                    // removing of recently deleted detail fields in the parent object.
                    for (let j = 0; j < this.form_data.form_detail_fields.length; j++) {
                        const parent_el = this.form_data.form_detail_fields[j];
                        for (let k = 0; k < parent_el.form_detail_field_value.length; k++) {
                            const child_el = parent_el.form_detail_field_value[k];
                            if(this.recently_removed_detail_ids.includes(child_el.id)) {
                                parent_el.form_detail_field_value.splice(k, 1)
                            }
                        }
                    }

                    for (var key in this.detail_field_values) {
                        array_.push(key)
                    }
                    array_.sort(function(a, b){return a.localeCompare(b, 'en', { numeric: true })});
                    for (var key in array_) {
                        let str = array_[key].split("#");
                        let field = this.form_data.form_detail_fields.find(item => item.id === parseInt(str[1].split('_')[1])).form_detail_field_value;
                        let row = {
                            id: field.filter(item => item.line_number === str[0].split('_')[1]).length > 0 ? field.filter(item => item.line_number === str[0].split('_')[1])[0].id : null, // form header footer field value
                            fdf_id: str[1].split('_')[1], //field_id
                            value:  this.detail_field_values[array_[key]] ? this.detail_field_values[array_[key]] : null, 
                            parameter_name:  this.form_data.form_detail_fields.find(item => item.id === parseInt(str[1].split('_')[1])).field.parameter_name, 
                            line:  str[0].split('_')[1] //row number = line *line starts in 1 
                        }

                        // updating of lines
                        let currentLine = parseInt(str[0].split('_')[1])
                        let sorted =this.deleted_lines.sort(function(a, b){return b - a});
                        for (let k = 0; k < sorted.length; k++) { //2,3
                            const element = this.deleted_lines[k];
                            if(parseInt(element) <= currentLine) {
                                currentLine = currentLine-1;
                                row.line = currentLine.toString();
                            }
                        }
                        d_field_data.push(row);
                    }
                    // End details or lines

                    let data = {
                        header_footer_field_data: hf_field_data,
                        detail_field_data: d_field_data,
                    }

                    if(this.af_and_columns.length >= 0) {
                        data.af_and_columns = this.af_and_columns;
                    }

                    if(this.recently_removed_detail_ids.length > 0) {
                        data.recently_removed_detail_ids = this.recently_removed_detail_ids;
                    }

                    return axios.post('/form-managements/form-data/update/'+this.$route.params.id, data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.recently_removed_detail_ids = [];
                            this.deleted_lines = [];
                            this.header_footer_field_values = {};
                            this.header_footer_field_values_backup = {};
                            this.detail_field_values = {};
                            this.detail_field_values_backup = {};
                            this.af_and_columns = [];
                            this.cancelEditCounter++
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.$route.params.id.split('~')[0]}</b> form has been updated.`,
                            })
                            this.toggleEdit();
                            this.getFormData()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            });

        },
        updateMainFileInTheDocumentRouting: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to update the main file of a routing with reference number <b>${this.$route.params.id.split('~')[0]}</b>.
                <br>
                <br>
                    <i>Please be certain, this action cannot be undone.</i>
                `,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, update.",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    return this.getFormData().then(response => {
                        if(response.status == 200) {
                            const data = response.data.data;
                            switch (data.printout_source) {
                                case 'Default':
                                let rowCounter = 0;
                                for (let index = 0; index < data.form_detail_fields.length; index++) {
                                    const element = data.form_detail_fields[index];
                                    if(element.form_detail_field_value.length > rowCounter) {
                                        rowCounter = element.form_detail_field_value.length
                                    }
                                }
                                let sorted_detail_fields = FormManagementJS.sortDetailFields(data.form_detail_fields);
                                this.$refs._formDataUpdate.control_number = `Ref. No.: ${this.$route.params.id.split('~')[0]}`
                                this.$refs._formDataUpdate.detailsRowCount = rowCounter;
                                this.$refs._formDataUpdate.organization_logo = this.$store.getters.getOrganizationLogo;
                                this.$refs._formDataUpdate.header_fields = FormManagementJS.generateHeaderFooterField(data.form_header_footer_fields, 'header');
                                this.$refs._formDataUpdate.detail_fields = FormManagementJS.generateDetailField(sorted_detail_fields);
                                this.$refs._formDataUpdate.footer_fields = FormManagementJS.generateHeaderFooterField(data.form_header_footer_fields, 'footer');
                                this.$refs._formDataUpdate.header_footer_field_values = FormManagementJS.generateHeaderFooterFieldValue(data.form_header_footer_fields);
                                this.$refs._formDataUpdate.detail_field_values = FormManagementJS.generateDetailFieldValue(data.form_detail_fields);
                                return this.createPDF().then(base64_pdf => {
                                    return this.updateFile(base64_pdf).then(response => {
                                        if(response.status == 200) {
                                            this.$swal({
                                                icon: "success",
                                                title: "Success!",
                                                html: `The main file of a document routing reference number <b>${this.$route.params.id.split('~')[0]}</b>. has been successfully updated.`,
                                            })
                                        }
                                    })
                                });

                                default:

                                let datas = {
                                    reference_number:this.$route.params.id.split('~')[0],
                                    printout_source: data.printout_source,
                                    action:'preview'
                                }
                                return new Promise((resolve, reject) => {
                                    axios.post('/form-managements/form-data/route-custom-form',datas,{validateStatus: () => true}).then(response => {
                                        if(response.status == 200) {
                                            return this.updateFile(response.data).then(response => {
                                                if(response.status == 200) {
                                                    this.$swal({
                                                        icon: "success",
                                                        title: "Success!",
                                                        html: `The main file of a document routing reference number <b>${this.$route.params.id.split('~')[0]}</b>. has been successfully updated.`,
                                                    })
                                                }
                                            })
                                        }
                                    })

                                })

                            }
                        }
                    });
                }
            })
        },
        updateFile: function (file) {
            return new Promise((resolve, reject) => {
                let pdfb64 = `data:application/pdf;base64,${file}`;
                let data = new FormData();
                data.append('reference_number', this.$route.params.id.split('~')[0]);
                data.append('updated_file', this.generatePDFFromBase64(pdfb64));
                axios.post('/form-managements/form-data/update-main-file-in-routing', data, {validateStatus: () => true})
                .then(response => {
                    resolve (response)
                }).catch(function (err) {
                    console.log(err);
                })
            })
        },
        removeFormDetailFieldElement: function (index, event) {
            if(Object.entries(this.detail_field_values).length > 0) {
                for (let i = 0; i < this.form_data.form_detail_fields.length; i++) {
                    const element = this.form_data.form_detail_fields[i];
                    let key = 'line_'+index+'#fieldid_'+element.id;
                    if(key in this.detail_field_values) {
                        let ind = index-1;
                        if(element.form_detail_field_value[ind]) {
                            this.recently_removed_detail_ids.push(element.form_detail_field_value[ind].id)
                        }
                    }
                    delete this.detail_field_values[key]
                }
            }
            this.deleted_lines.push(index);
            event.target.closest('.row').remove();
        },
        toggleEdit: function() {
            this.edit = !this.edit
            this.disable = (this.disable + 1) % 2
        },
        drpdwnAPIData: function (api) { 
            return new Promise((resolve, reject) => {
                axios.get(api, {validateStatus: () => true}).then(res => {
                    if(res.status == 200) {
                        resolve(res.data.data)
                    }
                })
            })
        },
        getFormData: function () {
            return new Promise((resolve, reject) => {
                axios.get('/form-managements/form-data/' + this.$route.params.id, {validateStatus: () => true})
                .then(response => {
                    if( response.status == 200 ) {
                        this.form_data = response.data.data
                        for (let index = 0; index < this.form_data.form_header_footer_fields.length; index++) {
                            const element = this.form_data.form_header_footer_fields[index];
                            if(element.form_header_footer_field_value) {
                                this.form_data.status = element.form_header_footer_field_value.deleted_at ? 'Inactive' : 'Active';
                                break;
                            }
                        }
                        this.assignData(response.data.data);
                        this.form_data_preview_loaded = true;
                        let rowCounter = 0;
                        for (let index = 0; index < this.form_data.form_detail_fields.length; index++) {
                            const elements = this.form_data.form_detail_fields[index].form_detail_field_value;
                            for (let i = 0; i < elements.length; i++) {
                                const el = elements[i];
                                if(parseInt(el.line_number) > rowCounter) {
                                    rowCounter = parseInt(el.line_number)
                                }
                            }
                        }
                        this.detailRowLine = rowCounter;
                    }
                    resolve (response)
                }).catch(function (err) {
                    console.log(err);
                })
            })
        },
        assignData: function (data) {
            // Setting up aggregates
            this.af_and_columns = [];

            this.header_footer_field_values = {};
            this.header_footer_field_values_backup = {};
            this.detail_field_values = {};
            this.detail_field_values_backup = {};

            for (let i = 0; i < data.form_header_footer_fields.length; i++) {
                const element = data.form_header_footer_fields[i];
                if(element.aggregate_function && element.aggregate_column_field_id) {
                    let row = {
                        id: element.form_header_footer_field_value.id,
                        fhfi_id: element.id,
                        aggregate_function: element.aggregate_function,
                        aggregate_column_field_id: parseInt(element.aggregate_column_field_id),
                    }
                    this.af_and_columns.push(row);
                }
            }

            // Setting up value to header & footer
            for (let i_header = 0; i_header < data.form_header_footer_fields.length; i_header++) {
                const element = data.form_header_footer_fields[i_header];
                if(element.form_header_footer_field_value) {
                    let el = {
                        [element.id]: element.form_header_footer_field_value.value 
                    }
                    Object.assign(this.header_footer_field_values, el)
                    Object.assign(this.header_footer_field_values_backup, el)
                }
            }

            // Setting up value to details 
            for (let index = 0; index < data.form_detail_fields.length; index++) {
                const element = data.form_detail_fields[index];
                if(element.field) {
                    let el;
                    let parent_count = 1;

                    for (let y = 0; y < element.form_detail_field_value.length; y++) {
                        const subElement = element.form_detail_field_value[y];
                        el = {
                            ['line_'+subElement.line_number+'#fieldid_'+subElement.fdf_id]: subElement.value 
                        }
                        parent_count++;
                        Object.assign(this.detail_field_values, el)
                        Object.assign(this.detail_field_values_backup, el)
                    }
                }
             }

             
            let details = FormManagementJS.sortDetailFields(data.form_detail_fields);

            this.header_fields = FormManagementJS.generateHeaderFooterField(data.form_header_footer_fields, 'header', true);
            this.detail_fields = FormManagementJS.generateDetailField(details, true);
            this.footer_fields = FormManagementJS.generateHeaderFooterField(data.form_header_footer_fields, 'footer', true);
            
            Object.assign(this.header_footer_field_values, FormManagementJS.generateDefaultValueToField(data.form_header_footer_fields));

            this.routing_status = data.routing_status;
            
            this.$Progress.finish()
        }
    },
    watch:  {
        large_list_modal: function (value) {
            if(!value) {
                this.large_list_selected_element = null;
                this.large_list_selected_element_index = null,
                this.large_list_selected_element_custom_usage = null;
                this.large_list_search = '';
            }
        }
    }
}
</script>